import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!, $longitude: Float, $latitude: Float) {
    login(username: $email, password: $password, longitude: $longitude, latitude: $latitude, product: "bet") {
      token
    }
  }
`;

export const SOCIAL_LOGIN_MUTATION = gql`
  mutation socialLogin($socialProvider: SocialProviderEnum!, $socialToken: String!, $socialSecretToken: String) {
    login(
      socialProvider: $socialProvider
      socialToken: $socialToken
      socialSecretToken: $socialSecretToken
      product: "bet"
    ) {
      token
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $displayName: String
    $email: String!
    $password: String
    $attribution: String
    $longitude: Float
    $latitude: Float
    $emailOnly: Boolean
    $state: String
    $phoneNumber: String
    $cxd: String
  ) {
    createUser(
      displayName: $displayName
      username: $email
      password: $password
      attribution: $attribution
      longitude: $longitude
      latitude: $latitude
      emailOnly: $emailOnly
      state: $state
      phoneNumber: $phoneNumber
      product: "bet"
      cxd: $cxd
    ) {
      token
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($state: String) {
    updateUser(state: $state) {
      ok
      token
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshToken($token: String!) {
    token(token: $token, product: "bet") {
      token
    }
  }
`;

export const CREATE_USER_SUBSCRIPTION = gql`
  mutation stripeCreateUserSubscription(
    $stripeToken: String!
    $subscriptionName: String!
    $couponId: String
    $sports: [String!]
  ) {
    stripeCreateUserSubscription(
      stripeToken: $stripeToken
      subscriptionName: $subscriptionName
      product: "bet"
      couponId: $couponId
      sports: $sports
    ) {
      token
      invoiceId
    }
  }
`;

export const UPDATE_USER_SUBSCRIPTION = gql`
  mutation stripeUpdateUserSubscription($currentUserSubscriptionId: UUID!, $cancelAtPeriodEnd: Boolean!) {
    stripeUpdateUserSubscription(
      currentUserSubscriptionId: $currentUserSubscriptionId
      cancelAtPeriodEnd: $cancelAtPeriodEnd
    ) {
      cancelAtPeriodEnd
      currentPeriodEnd
    }
  }
`;

export const CHANGE_USER_SUBSCRIPTION = gql`
  mutation stripeChangeUserSubscription(
    $currentUserSubscriptionId: UUID!
    $targetSubscriptionName: String!
    $sports: [String!]
  ) {
    stripeChangeUserSubscription(
      currentUserSubscriptionId: $currentUserSubscriptionId
      targetSubscriptionName: $targetSubscriptionName
      sports: $sports
    ) {
      token
    }
  }
`;

export const UPDATE_USER_PAYMENT_SOURCE = gql`
  mutation stripeUpdateUserPaymentSource($stripeToken: String!) {
    stripeUpdateUserPaymentSource(stripeToken: $stripeToken) {
      ok
    }
  }
`;

export const UPDATE_USER_SPORT = gql`
  mutation updateUserSport($sports: [LeagueEnum!]!) {
    updateUserSport(sports: $sports) {
      token
    }
  }
`;

export const SURVEY_ANSWER_MUTATION = gql`
  mutation answer($surveyId: UUID!, $answerId: UUID!) {
    submitSurvey(surveyId: $surveyId, answerId: $answerId) {
      id
      question
      startDate
      endDate
      answers {
        id
        surveyId
        answer
        count
      }
      userVoted
    }
  }
`;

export const UPDATE_USER_UI_SETTINGS: DocumentNode = gql`
  mutation updateUserUiSettingId($settingId: UUID!, $settingValue: String) {
    updateUserUiSettingId(settingId: $settingId, settingValue: $settingValue) {
      id
      parsedValue
      uiSection
      updateTs
    }
  }
`;

export const DELETE_USER_UI_SETTING: DocumentNode = gql`
  mutation deleteUserUiSetting($settingId: UUID!) {
    deleteUserUiSetting(settingId: $settingId) {
      status
    }
  }
`;

export const CREATE_USER_UI_SETTING: DocumentNode = gql`
  mutation createUserUiSetting(
    $league: LeagueEnum
    $section: UiGamePageSectionEnum
    $settingType: UiSettingTypeEnum!
    $settingValue: String!
  ) {
    createUserUiSetting(league: $league, section: $section, settingType: $settingType, settingValue: $settingValue) {
      id
      parsedValue
      uiSection
      updateTs
    }
  }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(username: $email, product: "bet") {
      ok
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation passwordReset($token: String!, $password: String!) {
    passwordReset(token: $token, password: $password) {
      ok
    }
  }
`;

export const CONTACT = gql`
  mutation contact(
    $name: String!
    $fromEmail: String!
    $toEmail: String!
    $subject: String!
    $captcha: String!
    $data: String!
  ) {
    contact(name: $name, fromEmail: $fromEmail, toEmail: $toEmail, subject: $subject, captcha: $captcha, data: $data) {
      ok
    }
  }
`;

export const SEND_EMAIL_VERIFICATION_CODE = gql`
  mutation sendEmailVerificationCode($email: String!) {
    sendEmailVerificationCode(email: $email) {
      email
      verified
    }
  }
`;

export const EMAIL_VERIFICATION_CONFIRMATION = gql`
  mutation emailVerificationConfirmation($email: String!, $verificationCode: String!) {
    emailVerificationConfirmation(email: $email, verificationCode: $verificationCode) {
      email
      verified
    }
  }
`;

export const SEND_ACCOUNT_DELETION_REQUEST = gql`
  mutation sendAccountDeletionRquest($email: String!) {
    sendAccountDeletionRequest(email: $email) {
      email
      success
    }
  }
`;

export const ADD_SURVEY_RESPONSE = gql`
  mutation addSurveyResponse($surveyId: String!, $answer: String!) {
    addSurveyResponse(surveyId: $surveyId, answer: $answer) {
      id
      answer
    }
  }
`;

export const REDEEM_VOUCHER = gql`
  mutation redeemVoucher($code: String!) {
    redeemVoucher(code: $code) {
      code
      provider
      token
    }
  }
`;

export const USER_SAVE_BOOKS = gql`
  mutation userSaveBooks($bookIds: [UUID!]!) {
    userSaveBooks(bookIds: $bookIds) {
      token
      user {
        id
        selectedBooksOn
        books {
          id
        }
      }
    }
  }
`;

export const USER_ADD_BOOK = gql`
  mutation userAddBook($bookId: UUID!) {
    userAddBook(bookId: $bookId) {
      id
      books {
        id
      }
    }
  }
`;

export const USER_REMOVE_BOOK = gql`
  mutation userRemoveBook($bookId: UUID!) {
    userRemoveBook(bookId: $bookId) {
      id
      books {
        id
      }
    }
  }
`;
